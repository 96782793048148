import styled from 'styled-components';
import { Text } from '@naf/text';
import { spacing, breakpoints } from '@naf/theme';
import { GridCol } from '@naf/grid';

const Header = styled(Text)`
  margin: ${spacing.space32} 0;
`;

const Form = styled.form`
  display: grid;
  grid-column: auto / span 12;
  grid-template-columns: repeat(12, 1fr);
`;

const IndentCol = styled(GridCol)`
  grid-column: 2 / span 11;

  @media (max-width: ${breakpoints.s}) {
    grid-column: auto / span 12;
  }
`;

const ToggleCol = styled(GridCol)`
  margin: calc(${spacing.space24} + 3px) ${spacing.space24} 0 0;
  display: flex;
  align-self: start;
  align-items: center;
`;

const ButtonGroup = styled.div`
  display: flex;
  margin-top: ${spacing.space64};

  button:not(:first-child) {
    margin-left: ${spacing.space32};
  }

  @media (max-width: ${breakpoints.s}) {
    flex-direction: column;

    button {
      width: 100%;
    }

    button:not(:first-child) {
      margin-left: 0;
      margin-top: ${spacing.space16};
    }
  }
`;

export default { Header, Form, IndentCol, ToggleCol, ButtonGroup };
